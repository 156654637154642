footer{
	@extend %cover;
	position:fixed;
	pointer-events: none;

	z-index: z('footer');
	
	#social{
		&:after{
			content: none;
			position: absolute;
			left: 0;
			bottom: -6.5rem;
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, #000000 100%);
			width: 100%;
			height: 15.5rem;
			z-index: -1;
			pointer-events: none;

			@include media('<phone') {
				content: "";
				bottom: 0;
				height: 12.5rem;
			}
		}
	}
	.social-items{
		position:absolute;
		bottom: 2.5rem;
		left: 6rem;
		display: flex;

		@include media('<phone') {
			left: 3rem;
		}
		.social-item{
			display: inline-block;
			margin-left: 3rem;
			pointer-events: auto;
			
			@include media('<phone') {
				margin-left: 2.6rem;
			}
			
			.social-item-link{
				display: flex;
				
				.facebook{
					width: 1.1rem;

					@include media('<phone') {
						width: 1.6rem;
					}
				}
				.twitter{
					width: 1.7rem;

					@include media('<phone') {
						width: 2.2rem;
					}
				}
				.linkedin{
					width: 1.4rem;

					@include media('<phone') {
						width: 2rem;
					}
				}
				.dribbble{
					width: 1.6rem;

					@include media('<phone') {
						width: 2.1rem;
					}
				}
				.behance{
					width: 1.9rem;

					@include media('<phone') {
						width: 2.6rem;
					}
				}
			}
			&:first-child{
				margin-left: 0;
			}
		}
	}
	.contact{
		position: absolute;
		right: 6rem;
		bottom: 2.5rem;
		font-size:1.6rem;
		pointer-events: auto;

		@include media('<phone') {
			right: 3rem;
		}
		@include media('<phone') {
			font-size: 2.6rem;
		}
	}
}