.home{
	@extend %cover;
	@extend %page;
}
.home-container{
	padding: 10rem 0;
}
.home-label{
	@extend %label;
	text-align: center;
}
.projects-listing{
	
}
.project-details{
	display: block;
	width: 100%; // 100%
	text-align: center;
	padding: 1.5rem 0;
	margin: 1rem auto; // 1rem 0
	//border-top: 1px solid #2B2B2B;
	//overflow: hidden;
	position: relative;
	//height: 64px;
	//&:first-child{
	//	border-top:0
	//}
	
	.project-title{
		font-size: 4.5rem; // 4.5
		font-family: $font-primary;
		font-weight: 400;
		text-transform: uppercase;
		position: relative;
		
		&:after{
			content: attr(data-role);
			position: absolute;
			bottom: .1rem;
			font-family: $font-secondary;
			font-size: 1.5rem;
			margin-left: 1rem;
			opacity: 0;
			transform: translateX(100px);
			transition: .3s all ease-in-out;

			@include media('<phone') {
				content: none;
			}
		}
	}
	// Canvas
	.project-gallery-media{
		position: relative;
		z-index: -1;
		//width: 10rem;
		//height: 5rem;
		//margin: -5rem auto 0;
		pointer-events: none;
		//width: 40vw;
		//height: 20vw;
		//top: 50%;
		//left: 50%;
		//transform: translate(-50%, -50%);
	}
	.project-gallery-media-image{
		will-change: transform;
		visibility: hidden;
		//width: 100%;
		//height: 100%;
		width: 36.5rem; // 9.5 // 33.5
		height: 15rem; // 4 // 15
		margin: -5rem auto 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		
	}
	//.project-gallery-media{
	//
	//}
	//.project-gallery-media-image{
	//	position: absolute;
	//	width: 40vw;
	//	height: 20vw;
	//	top: 50%;
	//	left: 50%;
	//	pointer-events: none;
	//	transform: translate(-50%, -50%);
	//	will-change: transform;
	//	visibility: hidden;
	//	//z-index: -1;
	//}
	&:hover{
		.project-title{
			&:after{
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}

						