.about{
	@extend %cover;
	@extend %page;
}
.about-container{
	padding-top: 10rem;
	
	h2{
		text-align: justify;
		font-size: 11.25rem;
		font-weight: 700;
		line-height: 12.8rem;
		text-transform: uppercase;
		width: 117rem;
		margin: 0 auto;
		font-family: $font-primary;

		@include media('<phone') {
			width: 69rem;
			font-size: 11em;
			line-height: 12.9rem;
			text-align: left;
		}
	}
	.intro{
		//margin: 15.3rem 0;
		
	}
}
.about-label{
	@extend %label;
	text-align: left;
	position: absolute;
	left: 16rem;
	top: 0rem;


	@include media('<phone') {
		left: 3rem;
	}
}
.content-container{
	position: relative;
	padding: 7rem 0;
	
	.content-info{
		margin: 0 auto;
		width: 116rem;

		@include media('<phone') {
			width: 69rem;
		}
		
		p{
			margin: 13rem 0;
			font-size: 2.7rem;
			line-height: 4rem;
			font-weight: 500;
			
			@include media('<phone') {
				margin: 2rem 0 5rem;
			}
			a{
				margin-left: 1rem;
				text-decoration: underline;
			}
		}
	}
}
.brands-container{
	position: relative;
	padding: 10rem 0;
	
	.brands-images{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 auto;
		width: 116rem;
		gap: 7rem 5rem;

		@include media('<phone') {
			width: 69rem;
		}
		.brands-image-container{
			max-width: 25rem;
			flex: 0 0 25rem;
			//height: 15rem;
			text-align: center;
		}
		.brand-image{
			max-width: 100%;

			@include media('<phone') {
				max-height: 60px;
				max-width: 90px;
			}
			//height: 100%;
		}
	}
}
.glitch{
	max-height: 13rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
#logo-glitch{
	animation: glitch 4s linear infinite;
	transform-origin: 50% 50%;
	fill:#fff;
}

@keyframes filter-in {
	0%, 20% {
		filter: url("#filter");
	}
	10% {
		filter: none;
	}
}
@keyframes glitch {
	1%{
		transform: rotateX(10deg) skewX(90deg);
	}
	2%{
		transform: rotateX(0deg) skewX(0deg);
	}
}