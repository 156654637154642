.image-container{
	width: 85vw;
	margin: 10rem auto;
	text-align: center;
	position: relative;
	height: 73rem;
	
	@include media('<phone') {
		width: 69rem;
	}
	img{
		max-width:100%;
		height: 100%;
	}
}