*,
*::after,
*::before {
	box-sizing: border-box;
	user-select: none
}

html {
	@extend %cover;

	background-color: $cod-gray;
	color: white;
	cursor: none;
	pointer-events:none;
	
	$vw: 100vw;
	font-size: calc($vw / 1920px * 10px);
	position: fixed;
	line-height: 1;
	overflow: hidden;

	@include media('<phone') {
		font-size: calc($vw / 750px * 10px);
	}
}
body{
	@extend %cover;
	
	font-family: $font-secondary;
	position: fixed;
}

a {
	color: inherit;
	outline: none;
	text-decoration: none;
	cursor: none;
}

button {
	border: none;
	background: none;
	color: inherit;
	cursor: pointer;
	outline: none;
}

img {
	//opacity: 0;
	transition: opacity 0.4s ease;
	vertical-align: middle;
	
	&.loaded {
		opacity: 1;
	}
}

canvas {
	pointer-events: none;
	position: relative;
	z-index: z('canvas')
}

main {
	@extend %cover;
}

[data-animation='paragraph'],
[data-animation='label'],
[data-animation='project'],
[data-animation='title'] {
	span {
		display: inline-block;
		overflow: hidden;
		vertical-align: top;
	}
}
.cursor {
	display: none;
	mix-blend-mode: difference;
}

@media (any-pointer: fine) {
	.cursor {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
		z-index: 9999;

		@include media("<phone"){
			display: none;
		}
	}
	.cursor__inner {
		fill: none;
		stroke: #fff;
		stroke-width: 1px;
		opacity: 1;
	}
	.no-js .cursor {
		display: none;
	}
}


.hover-reveal {
	position: absolute;
	z-index: -1;
	width: 40vw;
	height: 20vw;
	top: 0;
	left: 0;
	pointer-events: none;
	opacity: 0;
	will-change: transform;
}

.hover-reveal__inner {
	overflow: hidden;
}

.hover-reveal__inner,
.hover-reveal__img {
	width: 100%;
	height: 100%;
	position: relative;
	will-change: transform;
}

.hover-reveal__img {
	background-size: cover;
	background-position: 50% 50%;
	transform-origin: 50% 100%;
}
