.project{
	@extend %cover;
	@extend %page;
	
}
.project-intro{
	padding-bottom: 5rem; // 15rem
	
	.project-img-container{
		@include aspect-ratio(2.35, 1);
	}
	.project-img{
		//height: 70rem; // 70rem
		//width: 100vw;
		object-fit: cover;
		visibility: hidden;
		width: 100% !important;
		//@include media('<latptop') {
		//	height: 90rem;
		//}
		//@include media('<phone') {
		//	height: 40vh;
		//}
	}
	.project-title{
		text-align: center;
		font-size: 10vw;
		font-weight: 700;
		//line-height: 12.8rem;
		text-transform: uppercase;
		//margin-top: -10.5rem;
		transform: translateY(-10.5rem);
		font-family: $font-primary;

		@include media('<phone') {
			font-size: 14vw;
			transform: translateY(-5.5rem);
		}
	}
}
.project-container-content{
	padding-bottom: 5rem;
}


.project-info-container{
	margin: 0 auto;
	width: 116rem;
	display: flex;
	justify-content: space-between;

	@include media('<phone') {
		width: 69rem;
		flex-direction: column;
	}
	.project-info{
		margin-top: 7rem;

		@include media('<phone') {
			text-align: center;
		}
		&:first-child{
			margin-top: 0;
		}
	}
	.project-info-label{
		font-size: 2rem;
		line-height: 2rem;
		color: #494949;
		font-weight: 700;
		text-transform: uppercase;

		@include media('<phone') {
			font-size: 2.4rem;
			line-height: 2.4rem;
		}
	}
	.project-info-description{
		margin-top: .5rem;
		font-size: 3.7rem;
		line-height: 3.7rem;
		color: #fff;
		font-family: $font-primary;
		
		@include media('<phone') {
			font-size: 3.7rem;
			line-height: 3.7rem;
		}
		span{
			line-height: 1.5
		}
	}
}
.live-preview-container{
	@include media('<phone') {
		max-width: 20rem;
		flex: 0 0 20rem;
		margin: 10rem auto 0;
	}
}
.live-preview{
	display: block;
	position: relative;
	border: 1px solid #272727;
	padding: 1rem;
	border-radius: 50%;
	transition: .2s all ease-in-out;
	
	svg{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		
	}
	img{
		//-webkit-animation:spin 6s linear infinite;
		//-moz-animation:spin 6s linear infinite;
		//animation:spin 6s linear infinite;
		opacity: .8;
		max-width: 100%;
	}
	
	&:hover{
		border-color: #404040;

		
		img{
			opacity: 1;
			//animation-duration: 10s;
		}
	}
}

.project-gallery{
	//padding-top: 5rem;
}
.project-gallery-content{
	margin: 10rem auto;
	width: 85vw;
	//min-height: 50rem;
	//max-height: 100rem;
	text-align: center;
	
	@include aspect-ratio(11,6);
	@include media('<phone') {
		width: 69rem;
	}
}
.gallery-image{
	margin: 0 auto;
	max-width: 100%;
	object-fit: contain;
	height: 100%;
}
@-moz-keyframes spin {
	100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
	100% {
	 	-webkit-transform: rotate(360deg);
		transform:rotate(360deg);
	}
	
}