$mine-shaft: #2B2B2B;
$cod-gray: #1d1d1d;
$white: #ffffff;


$font-primary: 'Oswald', sans-serif;
$font-secondary: 'Poppins', sans-serif;

$ease-in-out: cubic-bezier(0.77, 0, 0.175, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

$z-indexes: ('preloader', 'header', 'footer', 'content', 'canvas');
