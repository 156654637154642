header{
	@extend %cover;
	position:fixed;
	z-index: z('header');
	pointer-events: none;
	
	#main-navigation{
		&:after{
			content: none;
			position: absolute;
			left: 0;
			top: -6.5rem;
			background: linear-gradient(to top, rgba(255,255,255,0) 0%, #000000 100%);
			width: 100%;
			height: 15.5rem;
			z-index: -1;
			pointer-events: none;

			@include media('<phone') {
				content: "";
				top: 0;
				height: 12.5rem;
			}
		}
		
		.logo{
			position: absolute;
			left: 6rem;
			top: 2.5rem;
			pointer-events: auto;

			@include media('<phone') {
				left: 3rem;
			}
			
			svg{
				width: 4.7rem;
				
				@include media('<phone') {
					width: 7rem;
				}
			}
		}
		.navigation-items{
			.navigation-item {
				position: absolute;
				right: 6rem;
				top: 3.9rem;
				pointer-events: auto;
				
				@include media('<phone') {
					right: 3rem;
					top: 4.4rem;
				}
				
				.navigation-item-link{
					font-size: 1.6rem;

					@include media('<phone') {
						font-size: 2.6rem;
					}
				}
			}
		}
	}
}
