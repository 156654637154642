.preloader{
	@extend %cover;
	@extend %center;
	
	text-align: center;
	background: $mine-shaft;
	pointer-events: none;
	
	//width: 100vw;
	//height: 100vh;
	flex-direction: column;
	
	z-index: z('preloader');

	p.preloader-text{
		color: $white;
		
		span{
			display: inline-block;
			overflow: hidden;
			line-height: 1;

			span{
				font-size: 6rem;
				line-height: 6.6rem;
				font-family: $font-primary;
				text-transform: uppercase;
			}
		}
	}
	.preloader-number{
		margin-top: 5rem;
		overflow: hidden;

		.preloader-number-text{
			font-size: 2rem;
			line-height: 2.4rem;
			font-weight: bold;
			color: $white;
		}
	}
}
