html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.project, .about, .home, footer, header, .preloader, main, body, html, .home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-label, .home-label {
  font-family: "Oswald", sans-serif;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 100;
}
.about-label span, .home-label span {
  display: block !important;
  font-weight: 400;
}

.dg.ac {
  z-index: 99999 !important;
}

.project, .about, .home {
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  user-select: none;
}

html {
  background-color: #1d1d1d;
  color: white;
  cursor: none;
  pointer-events: none;
  font-size: 0.5208333333vw;
  position: fixed;
  line-height: 1;
  overflow: hidden;
}
@media (max-width: 767px) {
  html {
    font-size: 1.3333333333vw;
  }
}

body {
  font-family: "Poppins", sans-serif;
  position: fixed;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: none;
}

button {
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}

img {
  transition: opacity 0.4s ease;
  vertical-align: middle;
}
img.loaded {
  opacity: 1;
}

canvas {
  pointer-events: none;
  position: relative;
  z-index: 1;
}

[data-animation=paragraph] span,
[data-animation=label] span,
[data-animation=project] span,
[data-animation=title] span {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}

.cursor {
  display: none;
  mix-blend-mode: difference;
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
    z-index: 9999;
  }
}
@media (any-pointer: fine) and (max-width: 767px) {
  .cursor {
    display: none;
  }
}
@media (any-pointer: fine) {
  .cursor__inner {
    fill: none;
    stroke: #fff;
    stroke-width: 1px;
    opacity: 1;
  }
  .no-js .cursor {
    display: none;
  }
}
.hover-reveal {
  position: absolute;
  z-index: -1;
  width: 40vw;
  height: 20vw;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  will-change: transform;
}

.hover-reveal__inner {
  overflow: hidden;
}

.hover-reveal__inner,
.hover-reveal__img {
  width: 100%;
  height: 100%;
  position: relative;
  will-change: transform;
}

.hover-reveal__img {
  background-size: cover;
  background-position: 50% 50%;
  transform-origin: 50% 100%;
}

.preloader {
  text-align: center;
  background: #2B2B2B;
  pointer-events: none;
  flex-direction: column;
  z-index: 5;
}
.preloader p.preloader-text {
  color: #ffffff;
}
.preloader p.preloader-text span {
  display: inline-block;
  overflow: hidden;
  line-height: 1;
}
.preloader p.preloader-text span span {
  font-size: 6rem;
  line-height: 6.6rem;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}
.preloader .preloader-number {
  margin-top: 5rem;
  overflow: hidden;
}
.preloader .preloader-number .preloader-number-text {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: bold;
  color: #ffffff;
}

.image-container {
  width: 85vw;
  margin: 10rem auto;
  text-align: center;
  position: relative;
  height: 73rem;
}
@media (max-width: 767px) {
  .image-container {
    width: 69rem;
  }
}
.image-container img {
  max-width: 100%;
  height: 100%;
}

header {
  position: fixed;
  z-index: 4;
  pointer-events: none;
}
header #main-navigation:after {
  content: none;
  position: absolute;
  left: 0;
  top: -6.5rem;
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #000000 100%);
  width: 100%;
  height: 15.5rem;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 767px) {
  header #main-navigation:after {
    content: "";
    top: 0;
    height: 12.5rem;
  }
}
header #main-navigation .logo {
  position: absolute;
  left: 6rem;
  top: 2.5rem;
  pointer-events: auto;
}
@media (max-width: 767px) {
  header #main-navigation .logo {
    left: 3rem;
  }
}
header #main-navigation .logo svg {
  width: 4.7rem;
}
@media (max-width: 767px) {
  header #main-navigation .logo svg {
    width: 7rem;
  }
}
header #main-navigation .navigation-items .navigation-item {
  position: absolute;
  right: 6rem;
  top: 3.9rem;
  pointer-events: auto;
}
@media (max-width: 767px) {
  header #main-navigation .navigation-items .navigation-item {
    right: 3rem;
    top: 4.4rem;
  }
}
header #main-navigation .navigation-items .navigation-item .navigation-item-link {
  font-size: 1.6rem;
}
@media (max-width: 767px) {
  header #main-navigation .navigation-items .navigation-item .navigation-item-link {
    font-size: 2.6rem;
  }
}

footer {
  position: fixed;
  pointer-events: none;
  z-index: 3;
}
footer #social:after {
  content: none;
  position: absolute;
  left: 0;
  bottom: -6.5rem;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #000000 100%);
  width: 100%;
  height: 15.5rem;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 767px) {
  footer #social:after {
    content: "";
    bottom: 0;
    height: 12.5rem;
  }
}
footer .social-items {
  position: absolute;
  bottom: 2.5rem;
  left: 6rem;
  display: flex;
}
@media (max-width: 767px) {
  footer .social-items {
    left: 3rem;
  }
}
footer .social-items .social-item {
  display: inline-block;
  margin-left: 3rem;
  pointer-events: auto;
}
@media (max-width: 767px) {
  footer .social-items .social-item {
    margin-left: 2.6rem;
  }
}
footer .social-items .social-item .social-item-link {
  display: flex;
}
footer .social-items .social-item .social-item-link .facebook {
  width: 1.1rem;
}
@media (max-width: 767px) {
  footer .social-items .social-item .social-item-link .facebook {
    width: 1.6rem;
  }
}
footer .social-items .social-item .social-item-link .twitter {
  width: 1.7rem;
}
@media (max-width: 767px) {
  footer .social-items .social-item .social-item-link .twitter {
    width: 2.2rem;
  }
}
footer .social-items .social-item .social-item-link .linkedin {
  width: 1.4rem;
}
@media (max-width: 767px) {
  footer .social-items .social-item .social-item-link .linkedin {
    width: 2rem;
  }
}
footer .social-items .social-item .social-item-link .dribbble {
  width: 1.6rem;
}
@media (max-width: 767px) {
  footer .social-items .social-item .social-item-link .dribbble {
    width: 2.1rem;
  }
}
footer .social-items .social-item .social-item-link .behance {
  width: 1.9rem;
}
@media (max-width: 767px) {
  footer .social-items .social-item .social-item-link .behance {
    width: 2.6rem;
  }
}
footer .social-items .social-item:first-child {
  margin-left: 0;
}
footer .contact {
  position: absolute;
  right: 6rem;
  bottom: 2.5rem;
  font-size: 1.6rem;
  pointer-events: auto;
}
@media (max-width: 767px) {
  footer .contact {
    right: 3rem;
  }
}
@media (max-width: 767px) {
  footer .contact {
    font-size: 2.6rem;
  }
}

.home-container {
  padding: 10rem 0;
}

.home-label {
  text-align: center;
}

.project-details {
  display: block;
  width: 100%;
  text-align: center;
  padding: 1.5rem 0;
  margin: 1rem auto;
  position: relative;
}
.project-details .project-title {
  font-size: 4.5rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
}
.project-details .project-title:after {
  content: attr(data-role);
  position: absolute;
  bottom: 0.1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  margin-left: 1rem;
  opacity: 0;
  transform: translateX(100px);
  transition: 0.3s all ease-in-out;
}
@media (max-width: 767px) {
  .project-details .project-title:after {
    content: none;
  }
}
.project-details .project-gallery-media {
  position: relative;
  z-index: -1;
  pointer-events: none;
}
.project-details .project-gallery-media-image {
  will-change: transform;
  visibility: hidden;
  width: 36.5rem;
  height: 15rem;
  margin: -5rem auto 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.project-details:hover .project-title:after {
  opacity: 1;
  transform: translateX(0);
}

.about-container {
  padding-top: 10rem;
}
.about-container h2 {
  text-align: justify;
  font-size: 11.25rem;
  font-weight: 700;
  line-height: 12.8rem;
  text-transform: uppercase;
  width: 117rem;
  margin: 0 auto;
  font-family: "Oswald", sans-serif;
}
@media (max-width: 767px) {
  .about-container h2 {
    width: 69rem;
    font-size: 11em;
    line-height: 12.9rem;
    text-align: left;
  }
}
.about-label {
  text-align: left;
  position: absolute;
  left: 16rem;
  top: 0rem;
}
@media (max-width: 767px) {
  .about-label {
    left: 3rem;
  }
}

.content-container {
  position: relative;
  padding: 7rem 0;
}
.content-container .content-info {
  margin: 0 auto;
  width: 116rem;
}
@media (max-width: 767px) {
  .content-container .content-info {
    width: 69rem;
  }
}
.content-container .content-info p {
  margin: 13rem 0;
  font-size: 2.7rem;
  line-height: 4rem;
  font-weight: 500;
}
@media (max-width: 767px) {
  .content-container .content-info p {
    margin: 2rem 0 5rem;
  }
}
.content-container .content-info p a {
  margin-left: 1rem;
  text-decoration: underline;
}

.brands-container {
  position: relative;
  padding: 10rem 0;
}
.brands-container .brands-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 116rem;
  gap: 7rem 5rem;
}
@media (max-width: 767px) {
  .brands-container .brands-images {
    width: 69rem;
  }
}
.brands-container .brands-images .brands-image-container {
  max-width: 25rem;
  flex: 0 0 25rem;
  text-align: center;
}
.brands-container .brands-images .brand-image {
  max-width: 100%;
}
@media (max-width: 767px) {
  .brands-container .brands-images .brand-image {
    max-height: 60px;
    max-width: 90px;
  }
}

.glitch {
  max-height: 13rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#logo-glitch {
  animation: glitch 4s linear infinite;
  transform-origin: 50% 50%;
  fill: #fff;
}

@keyframes filter-in {
  0%, 20% {
    filter: url("#filter");
  }
  10% {
    filter: none;
  }
}
@keyframes glitch {
  1% {
    transform: rotateX(10deg) skewX(90deg);
  }
  2% {
    transform: rotateX(0deg) skewX(0deg);
  }
}
.project-intro {
  padding-bottom: 5rem;
}
.project-intro .project-img-container {
  position: relative;
}
.project-intro .project-img-container:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 42.5531914894%;
}
.project-intro .project-img-container > img {
  position: absolute;
  display: block;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: initial;
}
.project-intro .project-img {
  object-fit: cover;
  visibility: hidden;
  width: 100% !important;
}
.project-intro .project-title {
  text-align: center;
  font-size: 10vw;
  font-weight: 700;
  text-transform: uppercase;
  transform: translateY(-10.5rem);
  font-family: "Oswald", sans-serif;
}
@media (max-width: 767px) {
  .project-intro .project-title {
    font-size: 14vw;
    transform: translateY(-5.5rem);
  }
}

.project-container-content {
  padding-bottom: 5rem;
}

.project-info-container {
  margin: 0 auto;
  width: 116rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .project-info-container {
    width: 69rem;
    flex-direction: column;
  }
}
.project-info-container .project-info {
  margin-top: 7rem;
}
@media (max-width: 767px) {
  .project-info-container .project-info {
    text-align: center;
  }
}
.project-info-container .project-info:first-child {
  margin-top: 0;
}
.project-info-container .project-info-label {
  font-size: 2rem;
  line-height: 2rem;
  color: #494949;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .project-info-container .project-info-label {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
}
.project-info-container .project-info-description {
  margin-top: 0.5rem;
  font-size: 3.7rem;
  line-height: 3.7rem;
  color: #fff;
  font-family: "Oswald", sans-serif;
}
@media (max-width: 767px) {
  .project-info-container .project-info-description {
    font-size: 3.7rem;
    line-height: 3.7rem;
  }
}
.project-info-container .project-info-description span {
  line-height: 1.5;
}

@media (max-width: 767px) {
  .live-preview-container {
    max-width: 20rem;
    flex: 0 0 20rem;
    margin: 10rem auto 0;
  }
}

.live-preview {
  display: block;
  position: relative;
  border: 1px solid #272727;
  padding: 1rem;
  border-radius: 50%;
  transition: 0.2s all ease-in-out;
}
.live-preview svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.live-preview img {
  opacity: 0.8;
  max-width: 100%;
}
.live-preview:hover {
  border-color: #404040;
}
.live-preview:hover img {
  opacity: 1;
}

.project-gallery-content {
  margin: 10rem auto;
  width: 85vw;
  text-align: center;
  position: relative;
}
.project-gallery-content:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 54.5454545455%;
}
.project-gallery-content > img {
  position: absolute;
  display: block;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: initial;
}
@media (max-width: 767px) {
  .project-gallery-content {
    width: 69rem;
  }
}

.gallery-image {
  margin: 0 auto;
  max-width: 100%;
  object-fit: contain;
  height: 100%;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}