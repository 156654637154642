%cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@mixin placholder{
	&.placeholder {@content; }
	&::-webkit-input-placeholder {@content; }
	&::-moz-input-placeholder {@content; }
	&:-moz-input-placeholder {@content; }
	&:-ms-input-placeholder {@content; }
}

@mixin ratio($width, $height) {
	position: relative;
	overflow: hidden;
	font-size: 0;
	
	&::after {
		content: '';
		display: inline-block;
		width: 100%;
		padding-top: $height / $width * 100%;
	}
}


@mixin aspect-ratio($width, $height) {
	position: relative;
	
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: calc($height / $width) * 100%;
	}
	> img {
		position: absolute;
		display: block;
		max-width: 100%;
		max-height: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: initial;
	}
}

%center {
	display: flex;
	align-items: center;
	justify-content: center;
}

%label {
	font-family: $font-primary;
	font-size: 2rem;
	line-height: 2.4rem;
	font-weight: 100;

	span{
		display:block !important;
		font-weight: 400;
	}
}